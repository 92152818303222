import * as React from "react";
import LayoutMain from "components/layout/main";
import HeaderLarge from "components/section/headerLarge";
import SourcesOverviewElearningPage from "../../components/section/sourcesOverviewElearningPage";

const ElearningPage = () => {
  return (
    <LayoutMain>
      <HeaderLarge title="E-learning" description="Op deze pagina vind je de openbare lokale e-learning modules. Deze modules zijn bereikbaar via de bijbehorende link." />
      <SourcesOverviewElearningPage />
    </LayoutMain>
  );
};

export default ElearningPage;
