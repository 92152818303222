import React from "react";
import { Grid, Row } from "rsuite";
import { useStaticQuery, graphql } from "gatsby";
import SectionWrapper from "./sectionWrapper";
import ExternalSourcePanel from "../ui/externalSourcePanel";

export default function SourcesOverviewElearningPage() {
  const data = useStaticQuery(graphql`
    query {
      allElearningCsv {
        nodes {
          type
          title
          subcategory
          source
          owner
          id
          description
          category
        }
      }
    }
  `);
  return (
    <>
      <SectionWrapper padded background>
        <Grid>
          <Row>
            {data.allElearningCsv.nodes.map((node) => (
              <ExternalSourcePanel
                title={node.title}
                description={node.description}
                source={node.source}
                type={node.type}
                category={node.category}
                owner={node.owner}
              />
            ))}
          </Row>
        </Grid>
      </SectionWrapper>
    </>
  );
}
